// JsFromRoutes CacheKey 01a957583446eccba46930c970089557
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@consumer/services/api'

export default {
  about: /* #__PURE__ */ definePathHelper('get', '/about'),
  press: /* #__PURE__ */ definePathHelper('get', '/press'),
  terms: /* #__PURE__ */ definePathHelper('get', '/legal'),
  privacy: /* #__PURE__ */ definePathHelper('get', '/privacy'),
  giftagreement: /* #__PURE__ */ definePathHelper('get', '/giftagreement'),
  howItWorks: /* #__PURE__ */ definePathHelper('get', '/how-it-works'),
  demo: /* #__PURE__ */ definePathHelper('get', '/demo'),
  giftIdeaLanding: /* #__PURE__ */ definePathHelper('get', '/buy-a-gift'),
  index: /* #__PURE__ */ definePathHelper('get', '/'),
}
